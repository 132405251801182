// src/MyCalendar.js
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const fetchClosestUpcomingEvent = async (calendarId, apiKey) => {
  const timeMin = new Date().toISOString(); // current time

  try {
    const response = await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events?orderBy=startTime&singleEvents=true&timeMin=${timeMin}&maxResults=1&key=${apiKey}`
    );
    if (!response.ok) {
      throw new Error('Network respon se was not ok');
    }
    const data = await response.json();
    return data.items[0]; // Return only the first (closest) event
  } catch (error) {
    console.error('Fetching closest upcoming event failed:', error);
    return null;
  }
};

const UpComingEvent = ({ calendarId, apiKey }) => {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    fetchClosestUpcomingEvent(calendarId, apiKey).then(upcomingEvent => {
      setEvent(upcomingEvent);
    });
  }, [calendarId, apiKey]);

  if (!event) {
    return <div className='flex text-black justify-center text-center text-lg lg:text-2xl  mb-24 items-center mx-auto min-w-[30vh] w-[33%]'>
      Future events will all be posted on the calendar below. At the moment,
      there are no volunteer events scheduled for this month, but check back later
      for any future updates.</div>;
  }

  const eventDate = new Date(event.start.dateTime || event.start.date).toLocaleString();

  // Function to add target="_blank" to all <a> tags
  const addBlankTarget = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer'); // For security
    });

    return doc.body.innerHTML;
  };

  const eventDescription = event.description ? addBlankTarget(event.description) : "No additional details available, at the moment.";

  // Sanitize the modified HTML content
  const cleanDescription = DOMPurify.sanitize(eventDescription);

  return (
    <div className='flex flex-col justify-center container mx-auto my-8 p-6 md:p-10 lg:p-12 max-w-md sm:max-w-xl md:max-w-3xl rounded-lg shadow-lg bg-white'>
      <h1 className='text-xl sm:text-2xl font-bold mb-8 text-left'>Upcoming Event:</h1>
      <h4 className='text-3xl md:text-4xl main_text font-bold'>{event.summary}</h4>
      <p className='text-xl md:text-2xl mt-4'>Date: {eventDate}</p>
      {/* Optionally display location if available */}
      {/* event.location && (
    <p className='text-2xl mt-2'>Location: {event.location}</p>
  ) */}
      {/* Display sanitized event description as HTML */}
      <div className='texl-lg md:text-xl mt-4' dangerouslySetInnerHTML={{ __html: cleanDescription }}></div>
    </div>

  );
};

export default UpComingEvent;