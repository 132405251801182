import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const ResourceItem = ({ organization, links, instagramLink }) => (
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start mb-8 pl-4 lg:pl-12 max-w-[90%]">
    <h2 className="text-3xl lg:text-4xl font-bold col-span-1 main_text">{organization}</h2>
    <div className="flex flex-col col-span-2 w-[40vh] md:w-[70vh]">
      {links.map((link, index) => (
        <a
          key={index}
          href={link}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="truncate text_links mb-2  md:w-[100%] text-sm md:text-base"
        >
          {link}
        </a>
      ))}
      {/* {instagramLink !== "NAN" && (
        <a
          href={instagramLink}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="main_text py-3 px-6 mt-4 border border-[#53C2C8] hover:text-white hover:bg-[#53C2C8] self-start"
        >
          FOLLOW {organization} ON INSTAGRAM
        </a>
      )} */}
      <div className=" md:ml-auto border-b border-black w-[40%] md:w-[60%] mt-9"></div>
    </div>

  </div>

);

function MigrationPolicy() {
  const resources = [
    {
      organization: "Brookings.Edu",
      links: [
        "https://www.brookings.edu/blog/order-from-chaos/2020/11/06/the-eus-new-pact-on-migration-and-asylum-is-missing-a-true-foundation/",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "The Conversation",
      links: [
        "https://theconversation.com/new-eu-migration-pact-driven-by-political-compromise-not-humanitarianism-146862",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "Origins",
      links: [
        "https://origins.osu.edu/article/refugees-or-immigrants-migration-crisis-europe-historical-perspective",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "CNN",
      links: [
        "https://www.cnn.com/2017/01/30/politics/trump-immigration-ban-refugees-trnd/index.html",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "Washington Post",
      links: [
        "https://www.washingtonpost.com/graphics/world/historical-migrant-crisis/",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "Holocaust Museum",
      links: [
        "https://exhibitions.ushmm.org/americans-and-the-holocaust/how-many-refugees-came-to-the-united-states-from-1933-1945",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "UNHCR",
      links: [
        "https://www.unhcr.org/gcr/GCR_English.pdf",
      ],
      instagramLink: "NAN",
    },
  ];

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />
      <div className="flex flex-col justify-center mx-auto items-center mt-16 mb-16">
        <h1 className="font-bold text-2xl sm:text-3xl main_text w-[40vh] sm:w-[50vh]">
          “Education is the most powerful weapon which you can use to change the
          world.”
        </h1>
        <h1 className="main_text text-left w-[40vh] sm:w-[50vh]">
          — Nelson Mandela
        </h1>
      </div>

      <div className="px-4 py-8">
        {resources.map((resource, index) => (
          <ResourceItem key={index} {...resource} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default MigrationPolicy;
