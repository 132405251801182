import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const ResourceItem = ({ organization, links, instagramLink }) => (
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start mb-8 pl-4 lg:pl-12 max-w-[90%]">
    <h2 className="text-3xl lg:text-4xl font-bold col-span-1 main_text">{organization}</h2>
    <div className="flex flex-col col-span-2 w-[40vh] md:w-[70vh]">
      {links.map((link, index) => (
        <a
          key={index}
          href={link}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="truncate text_links mb-2  md:w-[100%] text-sm md:text-base"
        >
          {link}
        </a>
      ))}
      {instagramLink !== "NAN" && (
        <a
          href={instagramLink}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="py-3 px-6 mt-4 btn_insta self-start"
        >
          FOLLOW {organization} ON INSTAGRAM
        </a>
      )}
      <div className=" md:ml-auto border-b border-black w-[40%] md:w-[60%] mt-9"></div>
    </div>

  </div>

);

function GeneralWebsites() {
  const resources = [
    {
      organization: "UNHCR",
      links: [
        "https://www.unhcr.org/blogs/statistics-refugee-numbers-highest-ever/",
        "https://www.unhcr.org/en-us/figures-at-a-glance.html/",
        "https://www.unhcr.org/en-us/",
      ],
      instagramLink: "https://www.instagram.com/usaforunhcr",
    },
    {
      organization: "UN",
      links: [
        "https://www.instagram.com/usaforunhcr/",
        "https://www.unrefugees.org/refugee-facts/",
        "https://www.unrefugees.org/news/",
      ],
      instagramLink: "https://www.instagram.com/refugees",
    },
    {
      organization: "Amnesty Int.",
      links: [
        "https://www.amnesty.org/en/latest/news/",
        "https://www.amnesty.org/en/what-we-do/refugees-asylum-seekers-and-migrants/",
        "https://www.amnesty.org/en/search/?q=refugees/",
      ],
      instagramLink: "https://www.instagram.com/amnesty",
    },
    {
      organization: "Vox",
      links: [
        "https://www.vox.com/2020/9/11/21431157/trump-refugee-admissions-resettlement-cap-2021",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "Social Workers",
      links: [
        "https://www.socialworkers.org/LinkClick.aspx?fileticket=X2QaNfEuJUk%3D&portalid=0",
      ],
      instagramLink: "NAN",
    },
  ];

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />
      <div className="flex flex-col justify-center mx-auto items-center mt-16 mb-16">
        <h1 className="font-bold text-2xl sm:text-3xl main_text w-[40vh] sm:w-[50vh]">
          “Education is the most powerful weapon which you can use to change the
          world.”
        </h1>
        <h1 className="main_text text-left w-[40vh] sm:w-[50vh]">
          — Nelson Mandela
        </h1>
      </div>

      <div className="px-4 py-8">
        {resources.map((resource, index) => (
          <ResourceItem key={index} {...resource} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default GeneralWebsites;
