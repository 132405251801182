import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useForm, ValidationError } from "@formspree/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPen } from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [state, handleSubmit] = useForm("mpwarjnl"); // Replace with your Formspree form ID
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    let successTimer, errorTimer;
    if (state.succeeded) {
      setShowSuccessMessage(true);
      successTimer = setTimeout(() => setShowSuccessMessage(false), 10000);
      resetForm();
    } else if (state.errors && state.errors.length > 0) {
      // Check if state.errors is not null
      setShowErrorMessage(true);
      errorTimer = setTimeout(() => setShowErrorMessage(false), 10000);
    }
    return () => {
      clearTimeout(successTimer);
      clearTimeout(errorTimer);
    };
  }, [state.succeeded, state.errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormState({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    });
  };

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />



      <div className="flex flex-col justify-center w-full mb-24">
        <div className="flex flex-col mt-12 md:mt-24 w-[70%] md:w-[65%] lg:w-[55%] mx-auto justify-center mb-16">
          <h2 className="text-3xl font-semibold">Contact</h2>
          <p>
            If you have any further questions, please don’t hesitate to leave
            us a message! We will do our best to get back to you as soon as
            possible.
          </p>
          <div>
            <p className="font-semibold">Students For Refugees</p>
            <a href="mailto:hello@studentsforrefugees.org" className="text-blue-500">
              hello@studentsforrefugees.org
            </a>
          </div>
          {/* <div>
            <p className="font-semibold">Link Tree:</p>
            <a href="https://linktr.ee/sfr2020" className="text-blue-500">
              https://linktr.ee/sfr2020
            </a>
          </div> */}
          {/* <div>
            <p className="font-semibold">Remind:</p>
            <p>Text: @sfr2021</p>
          </div> */}
        </div>




        <div className="flex mx-auto justify-center">

          <div className=" bg-white w-[50vh] sm:w-[60vh] h-full p-4 md:p-8 rounded shadow-lg">
            {showSuccessMessage && (
              <p className="text-lg text-green-500">Thanks for your message!</p>
            )}
            {showErrorMessage && (
              <p className="text-lg text-red-500">
                There was a problem. Please try again later.
              </p>
            )}
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  <span className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faUser} /> First Name
                  </span>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={formState.firstName}
                    onChange={handleChange}
                    background="white"
                    className="mt-1 block w-full border-2 border-gray-200 rounded py-2 px-3 shadow-sm outline-none focus:border-blue-500 disabled:bg-gray-100"
                    required
                    disabled={state.submitting}
                  />
                </label>
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  <span className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faUser} /> Last Name
                  </span>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={formState.lastName}
                    onChange={handleChange}
                    background="white"
                    className="mt-1 block w-full border-2 border-gray-200 rounded py-2 px-3 shadow-sm outline-none focus:border-blue-500 disabled:bg-gray-100"
                    required
                    disabled={state.submitting}
                  />
                </label>
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  <span className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faEnvelope} /> Email Address
                  </span>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={formState.email}
                    onChange={handleChange}
                    background="white"
                    className="mt-1 block w-full border-2 border-gray-200 rounded py-2 px-3 shadow-sm outline-none focus:border-blue-500 disabled:bg-gray-100"
                    required
                    disabled={state.submitting}
                  />
                </label>
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  <span className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faPen} /> Message
                  </span>
                  <textarea
                    id="message"
                    name="message"
                    value={formState.message}
                    onChange={handleChange}
                    background="white"
                    className="mt-1 block w-full border-2 border-gray-200 rounded py-2 px-3 shadow-sm outline-none focus:border-blue-500 disabled:bg-gray-100"
                    required
                    disabled={state.submitting}
                  ></textarea>
                </label>
              </div>
              {/* Optionally, insert validation error messages here */}
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <button
                type="submit"
                className="mt-4 py-2 px-4 border-none rounded btn-main text-white font-medium cursor-pointer transition-colors duration-300"
                disabled={state.submitting}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}

export default Contact;
