import React from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'

function OurVision() {
  return (
    <div>
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />

      <div className=' flex flex-col  justify-center items-center mx-auto h-screen mt-24 md:mt-28 lg:mt-16'>
        <p className='font-semibold'>OUR VISION</p>
        <h1 className='pt-2 font-bold text-3xl md:text-5xl text-center w-[25vh] sm:w-[30vh] md:w-[35vh]'>Making A Difference</h1>
        <h2 className='italic pt-16 font-semibold text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] text-center'>As of June 2021, the number of refugees has grown to 26.6 million (UNHCR) over half of whom are children under 18.</h2>
        <p className='pt-2 w-[40vh] sm:w-[45vh] md:w-[55vh] text-sm md:text-base justify-center text-justify'>The mission of Students for Refugees is to promote awareness and education of the ongoing refugee crisis worldwide, as a
          result of persecution, conflict, violence, and human rights violation. We work towards building an understanding of issues
          with migration: pre-migration, transmigration, and post-migration. We provide a platform for students to volunteer,
          advocate, and promote community participation for this vulnerable population, in collaboration with a multitude of agencies
          geared towards helping refugees. We know that our students will learn about resilience and perseverance through the stories
          of the refugees that they encounter. Our goal is to create a network of students interested in the promotion of human rights,
          peace and advocacy for the displaced refugee children and their families, starting in Houston, as the city has been hosting
          the largest number of resettled refugees in the United States.</p>
        <p className='pt-40 text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] items-center justify-center text-center'>Imran Humza Hanif</p>
        <p className='pt-4 pb-24 text-sm md:text-base w-[40vh] sm:w-[45vh] md:w-[55vh] items-center justify-center text-center'>Founder and President</p>
      </div>
      <Footer />
    </div>
  )
}

export default OurVision
