import React, { useState } from 'react';
import bannerImage from "../../images/Banner_LandingPage.jpg";
import Navbar from "../../components/Navbar/Navbar";
import { ReactTyped } from "react-typed";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useBranch } from '../../components/BranchProvider/BranchProvider.js';  // Import the BranchProvider


function Home() {
  const { branch, setBranch } = useBranch();
  const [showModal, setShowModal] = useState(false);

  const handleBranchSelection = (event) => {
    const selectedBranch = event.target.value;
    setBranch(selectedBranch);

    // Update all CSS variables based on the selected branch
    switch (selectedBranch) {
      case 'National Team':
        document.documentElement.style.setProperty('--main-color', '#53C2C8'); // Default main color
        document.documentElement.style.setProperty('--main-text-color', '#53C2C8'); // Default text color
        document.documentElement.style.setProperty('--main-hover-color', '#5299c8'); // Default hover color
        break;
      case 'Carnegie Vanguard':
        document.documentElement.style.setProperty('--main-color', '#0430EB'); // Main color for Branch 1
        document.documentElement.style.setProperty('--main-text-color', '#0430EB'); // Text color for Branch 1
        document.documentElement.style.setProperty('--main-hover-color', '#049eeb'); // Hover color for Branch 1
        break;
      case 'Bellaire':
        document.documentElement.style.setProperty('--main-color', '#E41937'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#E41937'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#e41919'); // Hover color for Branch 2
        break;
      case 'DeBakey':
        document.documentElement.style.setProperty('--main-color', '#0049AD'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#0049AD'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#049eeb'); // Hover color for Branch 2
        break;
      case 'Texas A&M':
        document.documentElement.style.setProperty('--main-color', '#510104'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#510104'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#850106'); // Hover color for Branch 2
        break;
      case 'Baylor Medicine':
        document.documentElement.style.setProperty('--main-color', '#003087'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#003087'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#049eeb'); // Hover color for Branch 2
        break;
      case 'Challenge':
        document.documentElement.style.setProperty('--main-color', '#FF0000'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#FF0000'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#e41919'); // Hover color for Branch 2
        break;
      case 'Lamar':
        document.documentElement.style.setProperty('--main-color', '#0100FD'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#0100FD'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#049eeb'); // Hover color for Branch 2
        break;
      case 'University of Houston':
        document.documentElement.style.setProperty('--main-color', '#C8102E'); // Main color for Branch 2
        document.documentElement.style.setProperty('--main-text-color', '#C8102E'); // Text color for Branch 2
        document.documentElement.style.setProperty('--main-hover-color', '#e41919'); // Hover color for Branch 2
        break;
      default:
        document.documentElement.style.setProperty('--main-color', '#53C2C8'); // Default main color
        document.documentElement.style.setProperty('--main-text-color', '#53C2C8'); // Default text color
        document.documentElement.style.setProperty('--main-hover-color', '#5299c8'); // Default hover color
        break;
    }

    setShowModal(false); // Close modal after selection
  };

  return (
    <div>
      <div
        className="w-full h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <Navbar />
        <div className="flex items-center justify-start h-screen pl-[5%] lg:mt-[-14vh] md:mt-[-12vh] sm:mt-[-10vh] mt-[-14vh]">
          <div className="max-w-[60%] w-full">
            <h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-4xl text-white font-bold">
              The future belongs to us.
            </h1>
            <div className="flex flex-col space-y-4">
              {" "}
              {/* Adjusted part */}
              <ReactTyped
                className="lg:text-5xl md:text-4xl sm:text-3xl text-3xl main_text font-bold"
                strings={["Learn More", "Volunteer", "Donate"]}
                typeSpeed={80}
                backSpeed={80}
                backDelay={2000}
                loop
              />
              <div className="flex flex-row space-x-4">
                {" "}
                <Link to="/about/vision">
                  <button className="bg-white hover:bg-[#ffffffc9] main_text font-bold py-4 px-3 md:py-3 md:px-6 text-[.7rem] md:text-lg lg:py-4 lg:px-8 lg:text-xl rounded-full mt-10 md:mt-16 lg:mt-3">
                    Learn More
                  </button>
                </Link>
                <Link to="/donate">
                  <button className="btn-main text-white font-bold py-4 px-5 md:py-3 md:px-6 text-[.8rem] md:text-lg lg:py-4 lg:px-8 lg:text-xl rounded-full mt-10 md:mt-16 lg:mt-3">
                    Donate
                  </button>
                </Link>
              </div>
              <button
                onClick={() => setShowModal(true)}
                className="btn-main text-white font-bold py-4 px-5 md:py-3 md:px-6 text-[.8rem] md:text-lg lg:py-4 lg:px-8 lg:text-xl rounded-full mt-10 md:mt-16 lg:mt-3 lg:w-[20rem] md:w-[17rem] w-[12rem]"
              >
                Select Branch
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-lg">
            <h2 className="text-2xl font-bold mb-4">Select Your Branch</h2>
            <select
              className="w-full p-2 border border-gray-300 rounded-md"
              onChange={handleBranchSelection}
            >
              <option value="default">Select</option>
              <option value="National Team">National Team</option>
              <option value="Carnegie Vanguard">Carnegie Vanguard</option>
              <option value="Bellaire">Bellaire</option>
              <option value="DeBakey">DeBakey</option>
              <option value="Texas A&M">Texas A&M</option>
              <option value="Baylor Medicine">Baylor Medicine</option>
              <option value="Challenge">Challenge</option>
              <option value="Lamar">Lamar</option>
              <option value="University of Houston">University of Houston</option>
            </select>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <Footer></Footer>
    </div>
  );
}

export default Home;
