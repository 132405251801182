// src/MyCalendar.js
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const fetchCalendarEvents = async (calendarId, apiKey) => {
  try {
    const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events?key=${apiKey}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error('Fetching calendar events failed:', error);
    return [];
  }
};

const convertEventsForCalendar = (googleEvents) => {
  return googleEvents.map(event => ({
    title: event.summary,
    start: new Date(event.start.dateTime || event.start.date),
    end: new Date(event.end.dateTime || event.end.date),
    allDay: !!event.start.date
  }));
};

const MyCalendar = ({ calendarId, apiKey }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchCalendarEvents(calendarId, apiKey).then(googleEvents => {
      const formattedEvents = convertEventsForCalendar(googleEvents);
      setEvents(formattedEvents);
    });
  }, [calendarId, apiKey]);

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: '80vh' }}
    />
  );
};

export default MyCalendar;
