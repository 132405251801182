import React from "react";
import Home from "./pages/Home/Home";
import Contact from "./pages/About/Contact/Contact";
import Vision from "./pages/About/OurVision/OurVision";
import EventGallery from "./pages/EventGallery/EventGallery";
import Donation from "./pages/Donate/Donate";
import IndividualEventGallery from "./components/IndividualEventGallery/IndividualEventGallery";
import ResourcesGen from "./pages/Resources/GeneralWebsites/GeneralWebsites";
import ResourcesAfr from "./pages/Resources/Africa/Africa";
import ResourcesLat from "./pages/Resources/Latin_America/Latin_America";
import ResourcesMid from "./pages/Resources/MiddleEast/MiddleEast";
import ResourcesPol from "./pages/Resources/MigrationPolicy/MigrationPolicy";
import Volunteer from "./pages/Volunteer/Volunteer";
import MeetTeam from "./pages/About/Team/Team";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/vision" element={<Vision />} />
        <Route path="/about/team" element={<MeetTeam />} />
        <Route path="/about/contact" element={<Contact />} />
        <Route exact path="/donate" element={<Donation />} />
        <Route exact path="/volunteer" element={<Volunteer />} />
        <Route path="/resources/general" element={<ResourcesGen />} />
        <Route path="/resources/africa" element={<ResourcesAfr />} />
        <Route path="/resources/latinamerica" element={<ResourcesLat />} />
        <Route path="/resources/middleeast" element={<ResourcesMid />} />
        <Route path="/resources/policy" element={<ResourcesPol />} />
        <Route exact path="/eventgallery" element={<EventGallery />} />
        <Route path="/eventgallery/:eventName" element={<IndividualEventGallery />} />
      </Routes>
    </Router>
  );
}

export default App;
