import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import UpComingEvent from "../../components/FetchUpcomingEvent/FetchUpcomingEvent";
import MyCalendar from "../../components/MyCalendar/MyCalendar";
const Volunteer = () => {
  return (
    <div className="min-h-screen">
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
      {/* Main content area that grows with content, centered horizontally */}
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-8 w-full mb-24">
        <h1 className="text-5xl lg:text-6xl font-bold main_text mt-24 mb-12 text-center">
          Volunteer Events
        </h1>
        {/* Ensure UpComingEvent and MyCalendar components are block elements or wrapped in divs as needed */}
        <div className="w-full max-w-4xl mx-auto">
          <UpComingEvent
            calendarId="a05e7777b88fbc6694ed2309d78aeafaa5d00d04ecfd8070894196cf018726a5@group.calendar.google.com"
            apiKey="AIzaSyDn41wJ9tVcyiQphc8B9O5Z690jdCe3UY0"
          />
        </div>
        <div className="w-full max-w-7xl mx-auto my-8">
          <MyCalendar
            calendarId="a05e7777b88fbc6694ed2309d78aeafaa5d00d04ecfd8070894196cf018726a5@group.calendar.google.com"
            apiKey="AIzaSyDn41wJ9tVcyiQphc8B9O5Z690jdCe3UY0"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Volunteer;
