import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Let's assume you have an events object like this
const events = {
  'Peace Farm SFR Feb 2024': require.context('../../compressedGalleryImages/Peace Farm SFR Feb 2024', false, /\.(webp)$/),
  'Covid-19 Relief Effort SFR Feb_May 2021': require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Feb_May 2021', false, /\.(webp)$/),
  'Covid-19 Relief Effort SFR Jul_Oct_Nov 2020': require.context('../../compressedGalleryImages/Covid-19 Relief Effort SFR Jul_Oct_Nov 2020', false, /\.(webp)$/),
  'P4P August 2024': require.context('../../compressedGalleryImages/P4P August 2024', false, /\.(webp)$/),
  'Peace Farm SFR Aug 2024': require.context('../../compressedGalleryImages/Peace Farm SFR Aug 2024', false, /\.(webp)$/),
  // ... other events
};

const EventGallery = () => {
  // Function to extract images from the webpack require context
  const extractImages = (context) => {
    const imageFiles = context.keys();
    const firstImageKey = imageFiles.find(key => key.includes('first_image')); // Search for 'first_image' in file names
    if (firstImageKey) {
      return [context(firstImageKey)]; // Return the specific 'first_image' if found
    } else {
      return [context(imageFiles.sort()[0])]; // Otherwise, return the alphabetically first image
    }
  };

  return (
    <div>
      <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
      {/* ... */}
      <div className="container mx-auto px-4 py-24">
        <h1 className="text-5xl font-bold text-center mb-12 main_text">Event Gallery</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {Object.entries(events).map(([eventName, imagesContext]) => {
            const images = extractImages(imagesContext);
            return (
              <div key={eventName} className="hover:shadow-lg p-4 border rounded main_text">
                <Link to={`/eventgallery/${encodeURIComponent(eventName)}`}>
                  <LazyLoadImage
                    alt={eventName}
                    effect="blur"
                    src={images[0]}
                    srcSet={`${images[0]} 400w, ${images[0]} 800w`} // Adjust paths and sizes accordingly
                    sizes="(max-width: 600px) 400px, 800px" // Adjust the sizes according to your layout
                    className="rounded"
                  />
                  <h2 className="font-bold mt-2">{eventName}</h2>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventGallery;
