import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BranchProvider } from './components/BranchProvider/BranchProvider.js';  // Import the BranchProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BranchProvider>  {/* Wrap your app with the BranchProvider */}
      <App />
    </BranchProvider>
  </React.StrictMode>
);
