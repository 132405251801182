import React from 'react'
import { Link } from "react-router-dom";

function Footer() {
  const handleClick = () => {
    // Using setTimeout to ensure it runs after the navigation
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <div>
      <div className="main_bg text-white w-full h-full pb-24">
        <h2 className="pt-[16vh] font-bold text-2xl w-[350px] sm:text-4xl sm:w-[500px] mx-auto justify-center items-center">“If you want others to be happy, practice compassion. If you want to be happy, practice compassion.”</h2>
        <h2 className="pt-[1vh] text-md sm:text-lg w-[350px] sm:w-[500px] mx-auto justify-center">— H.H Dalai Lama</h2>
        <p className="flex pt-[7vh] text-sm w-[350px] sm:w-[500px] mx-auto justify-center content-center">Students for Refugees</p>
        <p className="flex pt-[2vh] text-sm w-[350px] sm:w-[500px] mx-auto justify-center content-center">Est.2019</p>
        <Link to="/about/vision" onClick={handleClick}>
          <button className="flex justify-center mx-auto btn_lm py-3 px-6 md:text-lg lg:py-4 lg:px-8 lg:text-xl mt-12 md:mt-10 lg:mt-3 border border-white-500">
            Learn More
          </button>
        </Link>
        <a href="https://linktr.ee/studentsforrefugees" target="_blank" rel="noreferrer">
          <button className="flex justify-center mx-auto bg-white hover:bg-[#ffffffc9] font-bold text-black py-6 px-12 md:text-lg lg:text-xl mt-[10vh] sm:mt-[15vh]  ">
            VIEW OUR LINKED TREE
          </button>
        </a>
      </div>
    </div>
  )
}

export default Footer
