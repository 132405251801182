import React, { createContext, useState, useContext } from 'react';

// Create the context
const BranchContext = createContext();

// Custom hook to use the BranchContext
export const useBranch = () => useContext(BranchContext);

// Provider component
export const BranchProvider = ({ children }) => {
  const [branch, setBranch] = useState('');

  return (
    <BranchContext.Provider value={{ branch, setBranch }}>
      {children}
    </BranchContext.Provider>
  );
};
