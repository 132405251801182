import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { LuHelpingHand } from "react-icons/lu";
import RandomImageGallery from '../../components/RandomGallery.js/RandomGallery';

function Donate() {
  const percentage = 70; // For 70% progress

  const navigateToExternalPage = () => {
    window.open('https://buy.stripe.com/6oEfZ622UcD48ak4gg', '_blank', 'noopener,noreferrer');
  };


  return (
    <div>
      <div className='z-50'>
        <Navbar colorSFR="main_text" colorRest="text-[#9999A3]" colorBox="bg-black" colortextBox="text-white" colorhover="hover:bg-[#464646]" />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 mb-20 '>
        <div className='ml-0 px-10 lg:px-0 lg:ml-24 mt-[13vh] grid-cols-1'>
          <h1 className='main_text text-5xl sm:text-7xl text-left font-bold mb-1'>ONE</h1>
          <h1 className='main_text text-5xl sm:text-7xl text-left font-bold mb-1'>DONATION</h1>
          <h1 className='main_text text-5xl sm:text-7xl text-left font-bold pb-12'>AT A TIME</h1>
          <div className="text-left lg:w-[90%]">
            <p className='main_text text-lg sm:text-xl'>
              You can help transform the lives of refugees in need. Each contribution, no matter the size, plays a
              crucial role in providing essential resources and support. Together,
              we can make a lasting impact, offering hope and a brighter future to
              those who have lost so much. Your kindness fuels our mission to create
              positive change, one donation at a time.
            </p>
          </div>
          <Link to="/donate">
            <button onClick={navigateToExternalPage} className="btn-main text-white font-bold py-5 px-10 text-[.8rem] text-lg lg:py-5 lg:px-12 lg:text-2xl rounded-full mt-5 lg:mt-12">
              Donate →
            </button>
          </Link>
        </div>
        <div className='grid-cols-1 justify-center mt-[7vh] lg:mt-[5vh] mx-auto'>
          <div className='justify-center mx-auto mb-12'>
            <RandomImageGallery />
          </div>
          <div className="text-left w-[75vw] lg:w-[40vw]">
            <p className="main_text text-lg sm:text-xl">
              <strong>100% </strong>of your donation directly supports refugees, funding essential needs like hygiene products,
              non-perishable food, and clothing. Every contribution, no matter the size, makes a difference. No overhead is deducted, and your
              donation is <strong>tax-deductible</strong>. <strong>Thank you for your support.</strong>
            </p>
          </div>
        </div>
      </div>
      <div>
        <h1 className='px-5 text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold main_text mb-24 text-center'>How Your Donation Makes A Difference</h1>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 text-center main_text w-[90vw] mx-auto mb-20'>
          <div className='md:col-span-1 lg:col-span-1'>
            <p className='text-3xl'>1000+</p>
            <p className='text-2xl sm:text-3xl sm:max-w-[55vw] md:max-w-[50vw] lg:w-[25vw] justify-center mx-auto'>Hours of tutoring provided to refugee students</p>
          </div>

          <div className='md:col-span-1 lg:col-span-1'>
            <p className='text-3xl'>4000+</p>
            <p className='text-2xl sm:text-3xl sm:max-w-[55vw] md:max-w-[50vw] lg:w-[25vw] justify-center mx-auto'>Refugees helped in aid deliveries</p>
          </div>

          <div className='md:col-span-2 md:mx-auto lg:col-span-1'>
            <p className='text-3xl'>100+</p>
            <p className='text-2xl sm:text-3xl sm:max-w-[55vw] md:max-w-[50vw] lg:w-[25vw] justify-center mx-auto'>Active SFR members</p>
          </div>
        </div>

        {/* <RandomImageGallery />  */}
      </div>
      <Footer />


      {/* <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              styles={buildStyles({
                textColor: '#000', // Text color
                pathColor: '#3CB043', // Progress bar color
                trailColor: '#d3d3d3', // Background color of progress
              })}
            />
            <div className="text-center mt-4 ">
              <p className="main_text text-lg">Goal: $10,000</p>
              <p className="main_text text-lg">Total Raised: $7,000</p>
            </div> */}
    </div>
  );
}

export default Donate;
