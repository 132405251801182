import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const ResourceItem = ({ organization, links, instagramLink }) => (
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start mb-8 pl-4 lg:pl-12 max-w-[90%]">
    <h2 className="text-3xl lg:text-4xl font-bold col-span-1 main_text">{organization}</h2>
    <div className="flex flex-col col-span-2 w-[40vh] md:w-[70vh]">
      {links.map((link, index) => (
        <a
          key={index}
          href={link}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="truncate text_links mb-2  md:w-[100%] text-sm md:text-base"
        >
          {link}
        </a>
      ))}
      {/* {instagramLink !== "NAN" && (
        <a
          href={instagramLink}
          target="_blank" // if you want links to open in a new tab
          rel="noopener noreferrer"
          className="main_text py-3 px-6 mt-4 border border-[#53C2C8] hover:text-white hover:bg-[#53C2C8] self-start"
        >
          FOLLOW {organization} ON INSTAGRAM
        </a>
      )} */}
      <div className=" md:ml-auto border-b border-black w-[40%] md:w-[60%] mt-9"></div>
    </div>

  </div>

);

function MiddleEast() {
  const resources = [
    {
      organization: "UN News",
      links: [
        "https://news.un.org/en/story/2020/06/1066382",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "UNHCR",
      links: [
        "https://www.unrefugees.org/news/syria-refugee-crisis-explained/#:~:text=The%20Syrian%20refugee%20crisis%20is,increased%2C%20families%20began%20to%20flee.",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "Mercy Corps",
      links: [
        "https://www.mercycorps.org/blog/quick-facts-syria-crisis#start-syria-crisis",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "BBC News",
      links: [
        "https://www.bbc.com/news/50464561#:~:text=The%20oil%20and%20gas%20sector,countries%20in%20the%20Middle%20East.&text=But%20the%20country's%20oil%20production,the%20conflict%20began%20in%202011.",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "CFR",
      links: [
        "https://www.cfr.org/backgrounder/what-us-policy-israeli-palestinian-conflict",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "UN",
      links: [
        "https://www.un.org/press/en/2019/sc13895.doc.htm",
      ],
      instagramLink: "NAN",
    },
    {
      organization: "TIME",
      links: [
        "https://time.com/5773814/middle-east-peace-plan-response/",
      ],
      instagramLink: "NAN",
    },
  ];

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />
      <div className="flex flex-col justify-center mx-auto items-center mt-16 mb-16">
        <h1 className="font-bold text-2xl sm:text-3xl main_text w-[40vh] sm:w-[50vh]">
          “Education is the most powerful weapon which you can use to change the
          world.”
        </h1>
        <h1 className="main_text text-left w-[40vh] sm:w-[50vh]">
          — Nelson Mandela
        </h1>
      </div>

      <div className="px-4 py-8">
        {resources.map((resource, index) => (
          <ResourceItem key={index} {...resource} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default MiddleEast;
